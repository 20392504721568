import React from "react"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Aside } from "components/blog/aside"
import { Breadcrumb } from "components/breadcrumb"
import { DefaultHeader } from "components/headers/default-header"

const HowTwitterInstantWinWorks = ({ location }) => {
  const title = "Twitter Instant Win是如何运作的"
  const list = [
    { title: "常见问题", link: "/qa" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader lang="zh-CN" />
      <Layout lang="zh-CN">
        <Seo
          pagetitle={`FAQ | ${title}`}
          pagedesc="在活动期间，该方案继续收集指定推文的数据。 收集的数据可以以CSV格式输出，允许用户查看数据，如参与者的数量和参与者的追随者按时间的分布。"
          pagepath={location.pathname}
        />
        <Breadcrumb title="常见问题" items={list} />
        <div className="qa-page l-content l-container">
          <main className="l-main-content">
            <h1 className="qa-page__title">
              Twitter Instant Win是如何运作的？
            </h1>
            <p className="qa-page__answer">
              在活动期间，PARKLoT的系统与Twitter相连，收集转发指定推文的用户的数据。
              <br />
              检索到转发的用户数据后，程序会根据预先设定的抽奖概率自动抽奖。
              抽奖结果是通过Twitter的API直接发送的。
            </p>
            <p className="qa-page__text">
              包括我们公司在内的提供Twitter扩展的企业（即时赢、自动DM、标签收集等），使用Twitter提供的API来提供即时赢服务。
              (
              <a
                href="https://help.twitter.com/en/rules-and-policies/twitter-api#:~:text=Twitter的API平台提供了对公共Twitter数据的广泛访问，这些数据的提供者用户已经设置为全球共享；API允许用户管理私人Twitter信息（如直接信息），也允许这 向授权的开发商提供信息。"
                target="_blank"
                rel="noreferrer"
              >
                关于Twitter API的详细解释，请点击这里
              </a>
              )
              <br />
            </p>
            <p className="qa-page__text">
              如果你运行一个转发活动，如即时赢，或创建一个回应某些关键词的机器人应用程序，并需要进行大量的Twitter
              API调用以使应用程序工作，你将需要要求Twitter解锁API限制。
            </p>

            <p className="qa-page__text">
              <h2 className="qa-page__h2">关于Twitter的API</h2>
              推特的API平台提供了对广泛的公共推特数据的访问，这些数据的提供者用户已经设置为全球共享。
              该API允许用户管理Twitter的私人信息（如直接信息），同时也将这些信息提供给授权的开发者。
            </p>

            <div className="qa-page__text">
              <h2 className="qa-page__h2">你可以用TwitterAPI做什么</h2>
              Twitter的API允许你利用Twitter上的各种数据作为你自己方案的一部分。
              下面介绍其中的一些选择，其中一些可能与社交网络活动和即时赢利特别相关。
              <br />
              <br />
              <ol className="qa-page__ol">
                <li className="toc__item">发布一条推文</li>
                <li className="toc__item">检查指定的用户是否在关注你的账户</li>
                <li className="toc__item">
                  获得一个已经 "喜欢 "指定推文的用户列表
                </li>
                <li className="toc__item">
                  获得已经 "转发 "了指定推文的用户列表
                </li>
                <li className="toc__item">
                  获取引用和转发指定推文的用户列表以及发布的文本
                </li>
                <li className="toc__item">上传媒体（静态和视频图像）</li>
                <li className="toc__item">向指定的用户发送回复</li>
                <li className="toc__item">向指定的用户发送DMs</li>
              </ol>
            </div>

            <div className="qa-page__text">
              <h2 className="qa-page__h2">关于TwitterAPI的限制</h2>
              在使用Twitter
              API时，为了维护系统的可靠性，对账户操作规定了某些限制，包括
              <br />
              <br />
              <ol className="qa-page__ol">
                <li className="toc__item">
                  <a
                    href="https://help.twitter.com/en/rules-and-policies/twitter-limits#:~:text=推文：每天最多2,400条推文"
                    target="_blank"
                    rel="noreferrer"
                  >
                    2,400/天的回复
                  </a>
                </li>
                <li className="toc__item">
                  <a
                    href="https://help.twitter.com/en/rules-and-policies/twitter-limits#:~:text=直接信息（每天）：每天可发送1,000条信息"
                    target="_blank"
                    rel="noreferrer"
                  >
                    对于DM，限制是1000条信息/天
                  </a>
                </li>
              </ol>
              <br />
              以上是API限制的典型例子，
              <a
                href="https://help.twitter.com/en/rules-and-policies/twitter-limits"
                target="_blank"
                rel="noreferrer"
              >
                但Twitter API上的限制
              </a>
              比上面列出的要多得多。
              这些不是PARKLoT系统的限制，而是Twitter平台的限制。
              <br />
              一些限制可以通过获得认证徽章而得到放松。
            </div>
          </main>
          <Aside />
        </div>
      </Layout>
    </>
  )
}

export default HowTwitterInstantWinWorks
